define(['app', '$console'], (app, $console) => () => {
  const component = {
    element: null
  };

  const _config = {
    channel: 'loyaltyPointsMessage/update',
    select: '[data-component="loyaltyPointsMessage"]'
  };

  component.init = function(el) {
    component.element = el;

    app.subscribe(_config.channel, component.update);

    return component;
  };

  component.update = function(productId) {
    if (isNaN(productId)) {
      return;
    }

    app.ajax.get({
      url: `/${productId}.loyaltyPointsMessage?isProductPage=true`,
      success: component.success,
      error: () => component.error(productId)
    });
  };

  component.success = function(html) {
    const parent = component.element.parentElement;

    component.element.outerHTML = html;
    component.element = parent.querySelector(_config.select);
  };

  component.error = function(productId) {
    $console.error(`loyaltyPointsMessage: request failed for product ${productId}`);
  };

  return component;
});
